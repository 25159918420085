.user-select {
  border: 1px solid #f0f1f5 !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-radius: 12px !important;
}
select:required:invalid {
  color: gray;
}

.template-card {
  width: 373px;
}
.color-text {
  color: #f36c03;
  font-size: 12px;
  font-weight: 600;
}
.bg-grey {
  background-color: #b1abab1f;
}
.grid-fit-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 2rem;
}
.bg-image {
  background-image: url("../../images/Rectangle 109 (1).svg");
  height: auto;
  background-repeat: no-repeat;
  width: 100%;
}
.react-tel-input{
  width: auto;
}
.react-tel-input .form-control{
  height: 57px;
  width: 110px !important;
  font-size: 14px !important;
  border-radius: 9px;
  border: 1px solid #f0f1f5;
  padding: 18.5px 9px 18.5px 52px;
}
.react-tel-input .country-list{
  width: 250px !important;
}
