.phoneInput > input {
  display: none;
  width: 90px;
  border: none;
}
.phoneInput {
  width: 50px !important;
}

.profile-img {
  border-radius: 15px;
}
.react-tel-input{
  width: auto !important;
}
.conuntry-code li{
  padding: 5px 34px !important; 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.days{
  background-color: #F36C03;
  color: #fff;
  padding: 5px 8px;
  margin: 0;
  border-radius: 15px;
  margin-bottom: 10px;
}
.applyBtn{
  background-color: #f99346 !important;
  border: none !important;
  color: #fff !important;

}
.applyBtn:hover{
  background-color: #F36C03 !important;
  color: #fff !important;

}

.delete-bTn{
  background-color: #F36C03!important;
  border: none !important;
  color: #fff !important;
  padding: 5px;
  border-radius: 18px;
  width: 35px !important;
}
.delete-bTn:hover{
  background-color: #f99346 !important;
  color: #fff!important;
}
.bio-user {
  box-shadow: rgba(0, 0, 0, 0.1) 3px 2px 12px; /* background-color: aqua; */
  border-radius: 15px 5px 5px 15px;
}
.bio-name{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 5px;
}

.select-deal{
  display: flex;
  justify-content: center;
    justify-items: center;
    width: 31px !important;
    right: 1px;
    border-radius: 0px 10px 10px 0px !important;
    /* height: 40px !important; */
    border: none ;
}

.deal-input{
  border-radius: 10px 0px 0px 10px !important;

}

.select-deals{
  position: absolute;
    justify-items: center;
    width: 21px !important;
    right: 5px;
    height: 40px !important;
    top:9px  !important;
    border: none !important;
}

.bTon{
  background-color: #F36C03!important;
  border: none!important;
  color: #fff!important;
  padding: 5px;
  border-radius: 18px;
  padding: 5px 10px;
}

.auto-address.css-1wy0on6{
  display: none !important;
}
.css-1wy0on6{
  display: none !important;
}

.css-13cymwt-control{
    display: block !important;
    width: 100% !important;
    /* height: calc(2.6em + 0.80rem + 0px) !important;  */


    padding: 0.375rem 0.75rem !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #f0f1f5 !important;
    border-radius: 0.75rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important; 
}


.css-1hb7zxy-IndicatorsContainer{
  display: none !important;
}
.css-t3ipsp-control:hover{
  border: 1px solid #f0f1f5 !important;
}
.css-13cymwt-control:hover{
  border: 1px solid #f0f1f5 !important;
}
.css-b62m3t-container:hover{
border: none !important;
}
.edit-button{
  font-size: 2rem;
  border: none;
  background-color: transparent;
  color: #f68731;

}