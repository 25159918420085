.manubar>span{
    /* background-color: ; */
    background: #F36C03 !important;
    color: #F36C03;

}
.sideBar-tittle:hover{
    /* background: #F36C03 !important; */
    color: #F36C03 !important;
}
.action-btns{
    background-color: #f8e2d2f3 !important;
    color: #F36C03 !important;
    border: none !important;
    /* color: #fff; */
}
.action-btns:hover{
    background-color: #F36C03 !important;
    color: white !important;
    border: none !important;
}
.filter-options:hover{
background-color: #F36C03 !important;
color: #ffff !important;
font-weight: 700;
}

.date-filter{
 /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
 /* height: 6rem; */
    /* padding: 6px 6px 6px 6px; */
    /* border-radius: 10px; */
    /* max-width: 30rem; */
}
.date-filter label{
    color: 393838;
    margin-left: 5px;
}
.search-input{
    border: 1.2px solid #393838;
	/* padding: .2em .2em; */
	border-radius: 1.5em;
}
.date-filter input{
border: 1.2px solid #4b4a4a;
	padding: .5em .5em;
	border-radius: 1.5em;
	color: #393838;
    font-family: pappins;
	/* // &::-webkit-datetime-edit { padding: 1em; }
	// &::-webkit-datetime-edit-fields-wrapper { background: rgba(#E91E63, .2); }
	&::-webkit-datetime-edit-text { color: red; padding: 0 0.3em; }
	// &::-webkit-datetime-edit-month-field { color: #E91E63; }
	// &::-webkit-datetime-edit-day-field { color: #E91E63; }
	// &::-webkit-datetime-edit-year-field { color: #E91E63; }
	&::-webkit-inner-spin-button { display: none; }
	&::-webkit-calendar-picker-indicator { */
		/* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23E91E63' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'/%3E%3Cline x1='16' y1='2' x2='16' y2='6'/%3E%3Cline x1='8' y1='2' x2='8' y2='6'/%3E%3Cline x1='3' y1='10' x2='21' y2='10'/%3E%3C/svg%3E");  */
	/* } */
}

.pagination-buttons {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .prev-button,
  .next-button {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #858484;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100px !important;
    height: 35px;
  }
  
  .prev-button:disabled,
  .next-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    width: 100px !important;
    height: 35px;

  }
  
  .page-indicator {
    margin: 0 20px;
    font-size: 16px;
  }